import { render, staticRenderFns } from "./InsDrawer.vue?vue&type=template&id=767cdf58&scoped=true&"
import script from "./InsDrawer.vue?vue&type=script&lang=ts&"
export * from "./InsDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./InsDrawer.vue?vue&type=style&index=0&id=767cdf58&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767cdf58",
  null
  
)

export default component.exports